body {
  margin: 0;
}


@keyframes zoomUp {
  0% {
    transform: scale(1);
    }
  100% {
    transform: scale(1.15);
    }
  // 0% {
  //   transform: translateX(0);
  //   }
  // 100% {
  //   transform: translateX(50px);
  //   }
}

.swiper-slide-active div,
.swiper-slide-duplicate-active div,
.swiper-slide-prev div {
  animation: zoomUp 10s linear 0s 1 normal both; 
}


.slide-img img{
  display: block;
}